// /*jslint browser: true */

// Lenis
const lenis = new Lenis({ lerp: 0.2 });

lenis.on('scroll', () => ScrollTrigger.update());

function raf(time) { 
  lenis.raf(time); 
  requestAnimationFrame(raf); 
}
requestAnimationFrame(raf);

lenis.stop();

// Function to Preload images
const preloadImages = (images, callback) => {
  let loadedCount = 0;
  const totalImages = images.length;

  images.forEach((img) => {
    const image = new Image();
    image.src = img.src;
    image.onload = () => {
      loadedCount++;
      if (loadedCount === totalImages) {
        requestAnimationFrame(callback);
      }
    };
  });
};

// Coin Flip Animation Start
function startCoinFlip(section) {
  section.classList.add("coin-flip-start");
  gsap.fromTo(".bg-text", { opacity:0 }, { opacity:1, delay:1 } );
};

// Menu Active Section
function highlightActiveLink() {
  // Get all the menu links and sections
  const menuLinks = document.querySelectorAll('.anchor-link');
  const sections = document.querySelectorAll('article > section');

  // Create a ScrollTrigger for each section
  sections.forEach(section => {
    ScrollTrigger.create({
      trigger: section,
      start: 'top center',
      end: 'bottom center',
      onEnter: () => setActiveLink(section),
      onEnterBack: () => setActiveLink(section),
      onLeave: () => removeActiveLink(section),
      onLeaveBack: () => removeActiveLink(section),
    });
  });

  // Set active class to the corresponding menu link
  function setActiveLink(section) {
    menuLinks.forEach(link => {
      if (link.getAttribute('href').substring(1) === section.id) {
        link.classList.add('active');
      }
    });
  }

  // Remove active class from the menu link
  function removeActiveLink(section) {
    menuLinks.forEach(link => {
      if (link.getAttribute('href').substring(1) === section.id) {
        link.classList.remove('active');
      }
    });
  }
}

// Mobile: Mobile Navigation
function mobileNav() {
  const hamburgerIcon = document.getElementById('mobile-nav-button');
  const menuList = document.querySelector('.menu-list');
  const menuListUl = document.querySelector('#menu-main-nav');
  const menuLinks = menuList.querySelectorAll('.anchor-link');
  const activeClass = 'is--active';
  const mediaQuery = window.matchMedia('(max-width: 899px)');

  let isOpen = false;
  
  const menuTimeline = gsap.timeline({ defaults: { ease:'power3.out' }, paused:true, reversed:true })
    .to([menuList, menuListUl], { yPercent:0 });

  const toggleMenu = () => {
    if (!mediaQuery.matches) return;
    isOpen = !isOpen;
    menuTimeline.reversed() ? menuTimeline.play() : menuTimeline.reverse();
    hamburgerIcon.classList.toggle(activeClass, isOpen);
    hamburgerIcon.setAttribute('aria-expanded', isOpen);
  };
  const closeMenu = () => {
    if (!mediaQuery.matches) return;
    if (isOpen) {
      menuTimeline.reverse();
      hamburgerIcon.classList.remove(activeClass);
      hamburgerIcon.setAttribute('aria-expanded', isOpen);
      isOpen = false;
    }
  };
  const handleMediaChange = (e) => {
    if (e.matches) {
      if (!isOpen) {
        gsap.set(menuList, { yPercent: -100 });
        gsap.set(menuListUl, { yPercent: 100 });
      }
    } else {
      if (!isOpen) {
        gsap.set([menuList, menuListUl], { yPercent: 0 });
      }
    }
  };
  // Initial check and media query listener
  handleMediaChange(mediaQuery);
  mediaQuery.addEventListener('change', handleMediaChange);
  // Global event listeners
  hamburgerIcon.addEventListener('click', toggleMenu);
  menuLinks.forEach(link => link.addEventListener('click', closeMenu));
}

// KeenSlider
function keenSlider(selector, options) {
  const slider = new KeenSlider(selector, options);

  slider.on("dragStarted", () => {
    slider.container.classList.add("dragging");
  });
  slider.on("dragEnded", () => {
    slider.container.classList.remove("dragging");
  });

  return slider;
}

function sliderProgressBar(slider, selector) {

  const progressBar = document.getElementById(selector);

  const progressBarInner = document.createElement('div');
  progressBarInner.className = 'progress-bar-inner';
  progressBar.appendChild(progressBarInner);

  let isUpdating = false;
  const update = () => {
    if (isUpdating) return;
    isUpdating = true;

    requestAnimationFrame(() => {
      const { track } = slider;
      const totalSlides = track.details.maxIdx + 1;
      const currentSlideIndex = track.details.rel;

      if (totalSlides > 0) {
        const progress = (currentSlideIndex + 1) / totalSlides;
        progressBarInner.style.transform = `scale3d(${progress}, 1, 1)`;
      }

      isUpdating = false;
    });
  };

  slider.on('slideChanged', () => { setTimeout(update, 600); });
  slider.on('ready', update);

  update();
}

// Functions for Module: Intro
function animateIntro(section, video) {

  const introTl = gsap.timeline({
    defaults: { 
      duration: 1,
    },
    scrollTrigger: {
      trigger: section,
      start: 'top bottom',
      end: 'bottom top',
      toggleActions: "play reset play reset",
      onEnter: () => { video.play() },
      onLeave: () => { video.pause() },
      onEnterBack: () => { video.play() },
      onLeaveBack: () => { video.pause() }
    }
  });
  introTl
  .from(section.querySelectorAll('h1, p, li'), { 
    yPercent:50,
    opacity:0,
    stagger:0.1,
  }, 0.5);
  
}

// Functions for Module: Quote Images
function animateQuoteImages(section) {

  const target = section.querySelector('h2');
  const results = Splitting({ target: target, by: 'lines' });

  const textTl = gsap.timeline({
    defaults: { 
      duration: 1,
    },
    scrollTrigger: {
      trigger: section,
      start: 'top center',
      end: 'bottom top',
      toggleActions: "play none none reverse",
    }
  });

  // Animate each line's words
  results[0].lines.forEach((line, index) => {
    line.forEach((word) => {
      textTl.from(word, { 
        opacity: 0, 
        yPercent: 50,
      }, index * 0.25);
    });
  });

  // Em elements and separators
  textTl
  .from(section.querySelectorAll('.separator'), { scaleX: 0 }, 0)
  .to(section.querySelectorAll('em'), { color: '#F0B653' }, 1);


  // Parallax
  const images = section.querySelectorAll('img');
  const imagesBg = section.querySelector('.images-bg');

  const parallaxTl = gsap.timeline({
    scrollTrigger: {
      trigger: section,
      start: 'top bottom',
      end: 'bottom top',
      scrub: true,
      invalidateOnRefresh: true,
      onUpdate: self => {
        const progress = self.progress;

        // Halfway make images full opacity
        gsap.set(images, {
          opacity: gsap.utils.clamp(0, 1, progress * 2)
        });

        // Complete clipPath halfway through
        gsap.set(imagesBg, {
          clipPath: `inset(${(1 - progress * 2) * 6}vw)`
        });
      }
    }
  });

  gsap.utils.toArray(images).forEach(image => {
    const yPercentEnd = image.dataset.yPercentEnd || 100;
    parallaxTl.fromTo(image, 
      {
        willChange: 'transform, opacity',
        yPercent: -50,
      },
      {
        yPercent: yPercentEnd,
        ease: 'none'
      }, 0
    );
  });

}

//Functions for Parallax Banners
function animateParallaxBanners(section) {
  const image = section.querySelector('img');
  const parallax = 10;
  gsap.fromTo(image, 
    {
      'will-change': 'transform', 
      yPercent:-parallax, 
    },
    {
      yPercent: parallax,
      ease:'none',
      scrollTrigger: {
        trigger: section,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
        invalidateOnRefresh: true,
      }
    }
  );
}

//Functions for Sliding Objects
function animateSlidingObjects(object) {
  const slideValue = object.dataset.yPercent || 0;
    
  gsap.from(object, {
      willChange: 'transform',
      yPercent: slideValue,
      ease: 'power1.out',
      scrollTrigger: {
        trigger: object,
        start: 'top bottom',
        end: 'center center',
        scrub: true,
        invalidateOnRefresh: true,
      }
    }
  );
}

//Functions for Floating Objects
function animateFloatingObjects(object) {
  const rotationValue = object.dataset.rotation || 0;
    
  gsap.fromTo(object, 
    {
      willChange: 'transform',
      yPercent: 50,
      rotation: rotationValue,
    }, 
    {
      yPercent: -25,
      rotation: -rotationValue,
      ease: 'none',
      scrollTrigger: {
        trigger: object,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
        invalidateOnRefresh: true,
      }
    }
  );
}

document.addEventListener('DOMContentLoaded', function() {


  // GSAP Defaults
  gsap.defaults({ ease: 'quint.out', duration: 0.5 });

  
  // Preloader Animation
  const loader = document.getElementById("loader");
  const coinImages = loader.querySelectorAll("img");
  
  preloadImages(Array.from(coinImages), () => {
    startCoinFlip(loader);
  });
  

  // Start NProgress when the page starts loading
  NProgress.configure({  parent: '#progress-bar' }).start();

  // Sections to pass on
  const hero = document.getElementById('intro');
  const trailerVideo = document.getElementById('bgVideo');

  const revealPage = () => {
    gsap.to("#loader", {
      clipPath: 'inset(0 100% 0 0)',
      duration: 1,
      ease: 'quint.inOut',
      onStart: () => {
        animateIntro(hero, trailerVideo);
      },
      onComplete: () => {
        loader.remove();
        lenis.start();
      },
    });
  };

  const finishLoading = () => {
    NProgress.done();
    revealPage();
  };

  window.addEventListener("load", () => {
    setTimeout(() => {
      finishLoading();
    }, 4000);
  });
  

  highlightActiveLink();
  mobileNav();


  // Bigger Picture
  const bp = BiggerPicture({ target: document.body });
  
  function openBiggerPicture(e, items, video) {
    e.preventDefault();

    if (video) {
      video.pause();
    }

    bp.open({
      items: items,
      el: e.currentTarget,
      scale: 0.9,
      maxZoom: 1,
      intro: 'fadeup',
      onClose: () => {
        if (video) {
          video.play();
        }
      }
    });
  }

  const imageLinks = document.querySelectorAll('#sliderGallery a');
  const videoLinks = document.querySelectorAll('.lb-link');
  imageLinks.forEach(link => 
    link.addEventListener('click', e => 
      openBiggerPicture(e, imageLinks)
    )
  );
  videoLinks.forEach(link => 
    link.addEventListener('click', e => 
      openBiggerPicture(e, [e.currentTarget], trailerVideo)
    )
  );


  // Sliders
  const sliderCharacters = keenSlider("#slider", {
    mode: "free-snap",
    dragSpeed: 0.6,
    breakpoints: {
      '(max-width: 899px)': {
        slides: { perView: "auto", spacing: 16 },
      },
      '(min-width: 900px)': {
        slides: { perView: "auto", spacing: 24 },
      },
    },
  });
  const sliderGallery = keenSlider("#sliderGallery", {
    mode: "free-snap",
    dragSpeed: 0.6,
    slides: { perView: "auto", spacing: 8 },
  });
  sliderProgressBar(sliderCharacters, 'progress-bar-slider');
  sliderProgressBar(sliderGallery, 'progress-bar-gallery');

  // Module: Parallax Banners
  gsap.utils.toArray('.parallax').forEach(section => { animateParallaxBanners(section) });

  // Module: Quote Images
  gsap.utils.toArray('.quote-image').forEach(section => { animateQuoteImages(section) });
  
  // Element: Sliding Objects
  gsap.utils.toArray('.slide').forEach(object => { animateSlidingObjects(object) });

  // Element: Floating Objects
  gsap.utils.toArray('.float').forEach(object => { animateFloatingObjects(object) });

  // Element: Scoreboard Counter
  gsap.fromTo(".ba", 
    {
      innerHTML: 0
    }, {
      innerHTML: 9,
      duration: 3,
      ease: 'power3.inOut',
      onUpdate: function () {
        this.targets().forEach(el => el.innerHTML = Math.round(el.innerHTML));
      },
      scrollTrigger: {
        trigger: ".scoreboard",
        start: "top center",
        end: 'bottom top',
        toggleActions: "play none none reverse",
      }
    }
  );

});